import { createRouter, createWebHistory } from 'vue-router';
const routes = [
    {
        path: '/crm/distributor',
        component: () => import('@/layout/DistributorLayout.vue'),
        redirect: { name: 'Distributor' },
        children: [
            {
                path: '',
                name: 'Distributor',
                component: () => import('@/views/DistributorView.vue'),
            },
            {
                path: 'buat',
                name: 'CreateDistributor',
                component: () => import('@/views/CreateDistributorView.vue'),
            },
            {
                path: 'edit',
                name: 'EditDistributor',
                component: () => import('@/views/EditDistributorView.vue'),
            },
            {
                path: 'detail',
                name: 'DetailDistributor',
                component: () => import('@/views/DetailDistributorView.vue'),
            },
            {
                path: 'kontak-distributor',
                name: 'ContactDistributor',
                component: () => import('@/views/ContactView.vue'),
            },
            {
                path: 'kontak-distributor/buat',
                name: 'CreateContactDistributor',
                component: () => import('@/views/CreateContactView.vue'),
            },
            {
                path: 'kontak-distributor/edit',
                name: 'EditContactDistributor',
                component: () => import('@/views/EditContactView.vue'),
            },
            {
                path: 'kontak-distributor/detail',
                name: 'DetailContactDistributor',
                component: () => import('@/views/DetailContactView.vue'),
            },
        ],
    },
];
const router = createRouter({
    history: createWebHistory((import.meta.env || process.env).BASE_URL),
    routes,
});
export default router;
